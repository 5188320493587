import { createApi } from '@reduxjs/toolkit/query/react'
import BaseService from './BaseService'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosError } from 'axios'
import { FinalProductData } from '@/@types/lca'

const axiosBaseQuery =
    (): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
        },
        unknown,
        unknown
    > =>
        async (request) => {
            try {
                const response = BaseService(request)
                return response
            } catch (axiosError) {
                const err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }

const RtkQueryService = createApi({
    reducerPath: 'rtkApi',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getMaterials: builder.query({
            query: () => ({
                url: '/v1/raw-materials',
                method: 'GET',
            }),
        }),
        getProcess: builder.query({
            query: () => ({
                url: '/v1/process',
                method: 'GET',
            })
        }),
        getSuppliers: builder.query({
            query: () => ({
                url: '/v1/suppliers',
                method: 'GET',
            })
        }),
        getManufacturers: builder.query({
            query: () => ({
                url: '/v1/manufacturers',
                method: 'GET',
            })
        })
    }),
})

export const {
    useGetMaterialsQuery,
    useGetProcessQuery,
    useGetSuppliersQuery,
    useGetManufacturersQuery,
} = RtkQueryService

export default RtkQueryService
