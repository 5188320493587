import { FinalProductData, ManufacturingPlantData, ProcessData, ProductionQuantityData, RawMaterialData, TotalWaste, WasteData } from "@/@types/lca";
import { createSlice } from "@reduxjs/toolkit";

export interface LcaState {
    manufacturingPlant: ManufacturingPlantData[];
    finalProduct: FinalProductData[];
    rawMaterial: RawMaterialData[];
    process: ProcessData[],
    waste: WasteData,
    productionQuantity: ProductionQuantityData[]
}

const initialState: LcaState =  {
        manufacturingPlant: [],
        finalProduct: [],
        rawMaterial:[],
        process: [],
        waste: {
            totalwaste: {} as TotalWaste,
            checked: false,
            processWastes: []
        },
        productionQuantity: []
    }

const lcaSlice = createSlice({
    name: "lca",
    initialState: initialState,
    reducers: {
        addManufacturingPlant: (state, action) => {
            state.manufacturingPlant.push(action.payload)
        },
        updateManufacturingPlant: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.manufacturingPlant = state.manufacturingPlant.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        removeManufacturingPlant: (state, action) => {
            state.manufacturingPlant = state.manufacturingPlant.filter((item) => item.name !== action.payload.name)
        },
        addFinalProduct: (state, action) => {
            state.finalProduct.push(action.payload)
        },
        removeFinalProduct: (state, action) => {
            state.finalProduct = state.finalProduct.filter((item) => item.name !== action.payload.name)
        },
        updateFinalProduct: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.finalProduct = state.finalProduct.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        addRawMaterial: (state, action) => {
            state.rawMaterial.push(action.payload)
        },
        removeRawMaterial: (state, action) => {
            state.rawMaterial = state.rawMaterial.filter((item) => item.name !== action.payload.name)
        },
        updateRawMaterial: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.rawMaterial = state.rawMaterial.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        addProcess: (state, action) => {
            state.process.push(action.payload)
        },
        removeProcess: (state, action) => {
            state.process = state.process.filter((item) => item.name !== action.payload.name)
        },
        updateProcess: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.process = state.process.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        updateTotalWaste: (state, action) => {
            const {columnId, value} = action.payload
            state.waste.totalwaste = {...state.waste.totalwaste, [columnId]: value}
        },
        setChecked: (state, action) => {
            state.waste.checked = action.payload
        },
        createProcessWastes: (state) => {
            state.waste.processWastes = state.process.map((p) => {
                return {
                    process: p.name,
                    fuelConsumed: '',
                    electricity: '',
                    water: '',
                    solidWaste: '',
                    liquidWaste: ''
                }
            })
        },
        updateProcessWaste: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.waste.processWastes = state.waste.processWastes.map((p, index) => {
                if (index === rowIndex) {
                    return {...p, [columnId]: value}
                }
                return p
            })
        },
        addProductionQuantity: (state, action) => {
            state.productionQuantity.push(action.payload)
        },
        updateProductionQuantity: (state, action) => {
            const {rowIndex, columnId, value} = action.payload
            state.productionQuantity = state.productionQuantity.map((product, index) => {
                if (index === rowIndex) {
                    return {...product, [columnId]: value}
                }
                return product
            })
        },
        clearProductionQuantity: (state) => {
            state.productionQuantity = []
        },
        clearProcessWastes: (state) => {
            state.waste.processWastes = []
        },
        clearLcaSlice: () => initialState
    },
})

export const {
               addManufacturingPlant,
               updateManufacturingPlant,
               removeManufacturingPlant, 
               addFinalProduct, 
               removeFinalProduct, 
               updateFinalProduct,
               addRawMaterial, 
               removeRawMaterial, 
               updateRawMaterial,
               addProcess,
               removeProcess, 
               updateProcess,
               updateTotalWaste, 
               setChecked,
               createProcessWastes,
               updateProcessWaste,
               addProductionQuantity,
               updateProductionQuantity,
               clearProductionQuantity,
               clearProcessWastes,
               clearLcaSlice
            } = lcaSlice.actions
export default lcaSlice.reducer